.navbar {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 7rem;
  align-items: center;
  padding: 0 2rem;
}

.left,
.center,
.right {
  flex: 1;
}

.left {
  text-align: left;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.brand {
  font-size: 2.6rem; /* Slightly larger text */
  font-family: 'Lucida Calligraphy', 'Times New Roman', serif;
 font-weight: 700; /* Bold */
 text-decoration: none;  /* remove underline */
  color: white; /* Maintain existing color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for depth */
  letter-spacing: 0.05em; /* Slightly spaced out letters */
}

.nav {
  display: flex;
  gap: 1rem;
  font-size:1.3rem;
  align-items: flex-end;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .navbar {
    width: 100%;
    flex-direction: column;
    height: auto;
    padding: 1rem 0;
  }

  .brand {
    margin-bottom: 1rem;
    text-align: center; /* Add this property for center alignment on mobile */
  }

  .nav {
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    flex-wrap: wrap; /* Add this property to wrap nav items */
  }
}
