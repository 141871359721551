.greeting {
 
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0rem 1rem 1rem 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: -1rem 2rem 1rem 1rem;
  flex: 0.5;
  text-align: left;
}

.greeting h1 {
  font-size: 2.8em;
  font-weight: bold;
  color:white;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.greeting p {
  color:white;
  font-size: 1.7em;
  line-height: 1;

}

.greeting a {
  color: #007BFF;
  text-decoration: underline;
}
.loading-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}


.loading-mask .loader {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Media query for devices with max-width of 768px */
@media (max-width: 768px) {
  .greeting h1 {
    font-size: 2em; /* Adjust as needed */
    text-align: center;
  }

  .greeting p {
    font-size: 1.2em; /* Adjust as needed */
    text-align: center;
  }

  .greeting {
    flex: 1;

  }
  
}
