.essay {
  padding: 0rem 2rem 0.5rem 2rem;
  flex: 1.75;
  color: #01060f;
  background-color: #dfe4f3db;
  border-radius: 30px 30px 0px 0px;
  margin: 0rem 0rem 0rem 0rem;
}


@keyframes highlight {
  0% {background-color: transparent;}
  50% {background-color: yellow;}
  100% {background-color: transparent;}
}
.centerButton {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.contactBtn{
  background-color: #4e6cc6db;
}
.contactBtn:hover{
  background-color: #134c89;
}
.highlight {
  animation-name: highlight;
  animation-duration: 3s;
}


.essay p {
  font-size: 1.1em;

}

@media (max-width: 768px) {


}
