.aboutContainer {
  
  margin: 0 auto;
  padding: 20px;
  color: #01060f;
  background-color: #dfe4f3db;
  max-width: 50%; /* Adjust this value as needed */
  text-align: left;
  color: #01060f;
  background-color: #dfe4f3db;
  border-radius: 30px;
  flex:0.1;
}

@media (max-width: 768px) {
  .aboutContainer {
    max-width: 100%; /* Adjust this value as needed */
    border-radius: 30px 30px 0px 0px;
  }
  
  
}