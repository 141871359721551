.form-container {
  max-width: 600px;

}

.input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  border-radius: 25px;
  border: 2px solid #fff;
  overflow: hidden;
}

.form-input::placeholder {
  color: #B5B5B5; /* Light grey color for placeholder text. Adjust as necessary. */
}
.form-input:-webkit-autofill,
.form-input:-webkit-autofill:hover,
.form-input:-webkit-autofill:focus,
.form-input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px rgb(9, 2, 2) inset;
    -webkit-text-fill-color: white;
}

.form-input:focus {
  outline: none;
  background-color: transparent;
}

.form-input {
  caret-color: white; /* Makes the text cursor (caret) white for better visibility on a dark background. */
  /* The rest of your existing styles... */
  background: rgb(9, 2, 2);
  flex-grow: 1;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.5em;
}

.form-button {
  padding: 5px 18px;
  border: none;
  border-radius: 0 25px 25px 0;
  background-color: #20456c;
  color: white;
  
  cursor: pointer;
  font-size: 1em;
}

.form-button:hover {
  background-color: #134c89;
}

.form-error {
  margin-top: 10px;
}
@media (max-width: 768px) {
  .input-group {
    width: 100%;
  }
  .form-input {
    font-size: 1.4em;
  }
}