/* Blog.css */
.blog-cont {
  background-color: #dfe4f3db;
  border-radius: 30px 30px 0px 0px;
  width: "100%";
}
.blog {
    max-width: 800px;
    margin: 0 auto;
    color: #01060f;

    padding: 20px;
    line-height: 1.6;
  }
  
  .blog h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .blog h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .blog h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }
  
  .blog p {
    margin-bottom: 20px;
  }
  
  .blog ul {
    margin-bottom: 20px;
  }
  
  .blog li {
    margin-bottom: 10px;
  }
  