


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: snow;
  background-image: url("landing.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.App::after {
  content: "";
  background: rgba(0, 0, 0, 0.6); 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.App * {
  position: relative;
  z-index: 2;
}

.footer {
  margin-top: auto;
}
body, html {
  height: 100%;
}



.hero {
  flex: 1;  
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-container {
  display: flex;

  align-items: center;
  width: 100%;
}

.body {
  flex: 0.5;  /* This will make your body section take up the rest of the space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.explore-services-button {
  background-color: #007BFF;  /* Adjust color as needed */
  color: #ffffff;  /* Adjust color as needed */
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2em;  /* Adjust size as needed */
}

.explore-services-button:hover {
  background-color: #0069d9;  /* Adjust color as needed */
}
