.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  align-items: center;
  padding: 0 2rem;
  color: #01060f;
  background-color: #dfe4f3db;
}

.footer .left,
.footer .center,
.footer .right {
  flex: 1;
}

.footer .left {
  text-align: left;
}

.footer .center {
  text-align: center;
}

.footer .right {
  text-align: right;
}

.footer a {
  font-size: 1.5rem; /* size of the icon */
}

@media (max-width: 768px) {
  .footer {
    width: 100%;
    flex-direction: column;
    height: auto;
    padding: 1rem 0;
  }

  .footer .left,
  .footer .center,
  .footer .right {
    text-align: center; /* Center alignment on mobile */
    margin-bottom: 0.5rem;
  }
}

.social-icon {
  margin-left: 1rem; /* Additional margin */
}
.fa-tiktok {
  color: #000; /* TikTok's brand color */
}

.fa-tiktok:hover {
  color: #706363; /* Lighten color on hover for interaction feedback */
}

.facebook:hover {
  color: #8b9dc3;  /* Lighten color on hover for interaction feedback */
}

.fa-facebook-square {
  color: #3b5998;  /* Facebook's brand color */
}

.fa-facebook-square:hover {
  color: #8b9dc3;  /* Lighten color on hover for interaction feedback */
}

.fa-youtube {
  color: #c62020; /* YouTube's brand color */
}

.fa-youtube:hover {
  color: #ce9b9b; /* Lighten color on hover for interaction feedback */
}
