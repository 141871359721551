.contact-container {
  max-width: 75%; /* Adjust this value as needed */
  text-align: center;
  margin:0 auto;
  color: #01060f;
  background-color: #dfe4f3db;
  border-radius: 30px 30px 30px 30px;
  flex:0.5;
  padding: 20px;
}

.contact-form {

  background-color: #ffffff;
  display: grid;
  text-align: left;
  grid-template-columns: 1fr;
  gap: 10px;
  border-radius: 10px;
  font-size: large;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem; /* Add this property to reduce the bottom margin */
}
@media (max-width: 768px) {
  .contact-container {
    margin:0;
    flex: 1;
    max-width: 100%;
    border-radius: 30px 30px 0px 0px;
  }
  
  .contact-form {
    width: 100%;
  }
}
input,
textarea {
  width: 95%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

button {
  background-color: #4CAF50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}

